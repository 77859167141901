import React from 'react';
// import logo from './logo.svg';
import logo from './optimalpolicy-logo-pistar-pinksvg.svg';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />


    hello
      </header>

    </div>
  );
}

export default App;
